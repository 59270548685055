<template>
	<Start v-if="props.element.type === WorkflowObjectType.START" :element="props.element" />
	<template v-for="(childElement, childIndex) in (props.element && props.element.children) as WorkflowSchema[]" :key="childElement.id">
		<Entry v-if="childElement.type === WorkflowObjectType.ENTRY" :element="childElement" :element-address="elementAddress + 'children'" :element-index="childIndex" connector-view />
		<Exit v-if="childElement.type === WorkflowObjectType.EXIT" :element="childElement" :element-address="elementAddress + 'children'" :element-index="childIndex" connector-view />
		<Halt v-if="childElement.type === WorkflowObjectType.HALT" :element="childElement" :element-address="elementAddress + 'children'" :element-index="childIndex" connector-view />
		<BranchView v-if="childElement.type === WorkflowObjectType.BRANCH" :connector-view="true" :element="childElement" :step-tail-length="stepTailLength" :element-address="elementAddress + 'children'" :element-index="childIndex" />
		<Step v-if="childElement.type === WorkflowObjectType.STEP" :connector-view="true" :element="childElement" :element-address="elementAddress + 'children'" :element-index="childIndex" />
		<PlusArea v-if="childElement.type === WorkflowObjectType.PLUS" :element="childElement" :element-address="elementAddress + 'children'" :element-index="childIndex" />
		<End v-if="childElement.type === WorkflowObjectType.END" :element="childElement" :element-address="elementAddress + 'children'" :element-index="childIndex" />
	</template>
	<Connector v-if="props.element.type !== WorkflowObjectType.START" :source="getTailSourcePosition" :target="getTailTargetPosition" class="tail-connector" />
</template>

<script setup lang="ts">
	import Connector from './Connector.vue';
	import PlusArea from './PlusArea.vue';
	import Step from './Step.vue';
	import { computed, defineAsyncComponent, defineProps, withDefaults } from 'vue';
	import { WorkflowObjectType, WorkflowSchema } from '@/types/workflow';
	import End from '@modules/workflow/components/End.vue';
	import Start from '@modules/workflow/components/Start.vue';
	import Exit from '@modules/workflow/components/Exit.vue';
	import Entry from '@modules/workflow/components/Entry.vue';
	import Halt from '@modules/workflow/components/Halt.vue';

	const BranchView = defineAsyncComponent(() => import('./BranchView.vue'));

	interface Props {
		connectorView?: boolean;
		element: WorkflowSchema;
		branchLength?: number | null | undefined;
		elementAddress?: string;
		elementIndex?: number;
	}

	const props = withDefaults(defineProps<Props>(), {
		connectorView: true,
		transferData: undefined,
		branchLength: null,
		elementAddress: '',
		elementIndex: -1,
	});

	const stepTailLength = computed(() => {
		if (props.branchLength) {
			const len = props.element.length ? props.element.length : 0;
			return len - props.branchLength;
		} else {
			return 0;
		}
	});

	const getTailSourcePosition = computed(() => {
		const len = props.element.length ? props.element.length : 0;
		if (props.element?.position) {
			const position = props.element?.position;
			return { x: position?.x + 0.5, y: position?.y + len + 1 };
		} else {
			return { x: 0.5, y: 1 };
		}
	});

	const getTailTargetPosition = computed(() => {
		let tailLen = 0;
		if (props.branchLength) {
			const len = props.element.length ? props.element.length : 0;
			tailLen = props.branchLength - len - 1;
		}
		if (props.element.position) {
			const position = props.element.position;
			return { x: position?.x + 0.5, y: position?.y + (props.element.length ? props.element.length : 0) + tailLen + 1 };
		} else {
			return { x: 0.5, y: 1 + tailLen };
		}
	});
</script>
