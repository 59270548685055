<template>
	<component :is="tag" ref="el" @dragenter="emitEvent(events.dragenter, $event)" @dragleave="emitEvent(events.dragleave, $event)" @dragover="emitEvent(events.dragover, $event)" @drop="emitEvent(events.drop, $event)">
		<slot></slot>
	</component>
</template>

<script setup lang="ts">
	import { events } from '@/types/workflow';
	import { defineEmits, defineProps, ref, withDefaults } from 'vue';
	const insideElements = new Set();

	interface Props {
		tag?: string;
	}
	const props = withDefaults(defineProps<Props>(), {
		tag: 'div',
	});

	const tag = ref(props.tag);

	const emit = defineEmits(Object.values(events));

	const el = ref(null);
	const isDraggingOver = ref(false);

	const emitEvent = (name: events, nativeEvent: DragEvent) => {
		nativeEvent.preventDefault();

		emit(name, nativeEvent);
		/**
		 * After emitting the event, we need to determine if we're still
		 * dragging inside this Drop. We keep a Set of all elements that we've
		 * dragged into, then clear the data if that set is empty.
		 */
		// Add to the set on dragenter.
		if (name === events.dragenter) {
			if (insideElements.size || nativeEvent.target === el.value) {
				insideElements.add(nativeEvent.target);
			}
		}
		// Remove from the set on dragleave.
		if (name === events.dragleave) {
			insideElements.delete(nativeEvent.target);
		}
		// A drop resets everything.
		if (name === events.drop) {
			insideElements.clear();
		}
		// Finally, since Vue can't react to Set changes, set a flag indicating drag status.
		isDraggingOver.value = Boolean(insideElements.size);
	};
</script>
