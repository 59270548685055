import { WorkflowConfig } from '@/types/workflow';

export const workflowConfig: WorkflowConfig = {
	gapHeight: 70,
	exGapHeight: 140,
	gapWidth: 210,
	exBoxHeight: 50,
	boxHeight: 50,
	boxWidth: 200,
};
