<template>
	<div
		v-if="settings"
		class="z-20 cursor-pointer border-primary border-dashed"
		:class="{
			border: workflowStore.editingWorkblockAddress == elementAddress + '[' + elementIndex + ']',
		}"
	>
		<div
			class="flex justify-around items-center ring-primary shadow-md"
			:class="{
				border: sidebar || modal,
				'border-2': element?.id in workflowProcessStore.history,
				'active:shadow-none': sidebar || modal,
				'cursor-pointer': !sidebar,
				'cursor-grab': sidebar,
				'active:cursor-grabbing': sidebar,
				'bg-white': !disabled || sidebar || modal,
				'bg-gray-200': disabled && !sidebar && !modal,
				'border-blue-500': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 1,
				'border-green-500': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 2,
				'border-orange-400': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 3,
				'border-red-500': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 4,
			}"
			:style="{
				width: `${workflowConfig.boxWidth}px`,
				height: `${workflowConfig.boxHeight}px`,
				'border-color': settings.color,
			}"
		>
			<div
				class="flex flex-row w-full h-full"
				:class="{
					'opacity-20': disabled && !sidebar && !modal,
				}"
			>
				<div class="flex w-1/3 justify-center items-center" :class="settings.icon?.backgroundClass ?? {}" :style="settings.icon?.backgroundStyle ?? {}">
					<FontAwesomeIcon
						v-if="settings.icon?.icon || settings.icon?.iconName"
						:icon="settings.icon?.iconName ? resolveIconFromName(settings.icon.iconName) : settings.icon.icon"
						class="flex items-center justify-center pointer-events-none w-full h-1/2"
						:class="settings.icon?.iconClass ?? {}"
						:style="settings.icon?.iconStyle ?? {}"
					/>
					<img v-else-if="settings.icon?.svg" :src="settings.icon?.svg" alt="A workflow step" :class="settings.icon?.iconClass ?? {}" :style="settings.icon?.iconStyle ?? {}" />
				</div>
				<div
					class="flex flex-col w-full justify-center font-semibold text-lg px-1"
					:class="{
						'border-l': !!settings.icon?.separator,
					}"
				>
					<h2 class="font-semibold text-sm overflow-hidden" style="line-height: 1rem">{{ template.title }}</h2>
					<div class="text-xxs font-normal break-normal">{{ template.settings.detail }}</div>
				</div>
			</div>
		</div>
		<div
			v-if="workflowStore.showDetail && !sidebar && !modal"
			class="flex items-center border-solid border-t border-gray-300 bg-gray-50 z-20 shadow-md overflow-y-hidden"
			:style="{
				width: `${workflowConfig.boxWidth}px`,
				height: `${workflowConfig.exBoxHeight}px`,
				maxWidth: `${workflowConfig.boxWidth}px`,
				maxHeight: `${workflowConfig.exBoxHeight}px`,
			}"
		>
			<div class="font-normal text-xxxs overflow-y-auto justify-end p-1 w-full h-full">
				{{ template.description }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { workflowConfig } from '@/config/workflow';
	import { computed, defineProps, withDefaults } from 'vue';
	import { useWorkflowStore } from '../store';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { WorkblockTemplate } from '@/types/graphql';
	import { faDiagramProject, faFileInvoice, faHand, faHeadset, faLayerPlus, faPen, faSparkles, faStopwatch, faTypewriter, faValueAbsolute, faWrench, IconDefinition } from '@fortawesome/pro-light-svg-icons';
	import { WorkflowElementSettings, WorkflowSchema } from '@/types/workflow';
	import { useWorkflowProcessStore } from '@modules/workflow_process/store';

	const workflowStore = useWorkflowStore();
	const workflowProcessStore = useWorkflowProcessStore();

	interface Props {
		template: WorkblockTemplate;
		sidebar?: boolean;
		modal?: boolean;
		disabled?: boolean;
		element?: WorkflowSchema;
		elementAddress?: string;
		elementIndex?: number;
	}

	const props = withDefaults(defineProps<Props>(), {
		sidebar: false,
		modal: false,
		disabled: false,
		element: undefined,
		elementAddress: undefined,
		elementIndex: undefined,
	});

	const settings = computed<WorkflowElementSettings>(() => {
		return props.template.settings as WorkflowElementSettings;
	});

	const resolveIconFromName = (icon: string): IconDefinition | undefined => {
		if (icon === 'typewriter') {
			return faTypewriter;
		} else if (icon === 'halt') {
			return faHand;
		} else if (icon === 'wait') {
			return faStopwatch;
		} else if (icon === 'form') {
			return faFileInvoice;
		} else if (icon === 'variable') {
			return faValueAbsolute;
		} else if (icon === 'workflow') {
			return faDiagramProject;
		} else if (icon === 'wrench') {
			return faWrench;
		} else if (icon === 'pen') {
			return faPen;
		} else if (icon === 'headset') {
			return faHeadset;
		} else if (icon === 'new') {
			return faSparkles;
		} else if (icon === 'complex') {
			return faLayerPlus;
		}
	};
</script>
