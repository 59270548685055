<template>
	<div
		class="flex items-center rounded-full z-20 bg-white ring-primary shadow-md w-1/2"
		:class="{
			border: sidebar || modal || workflowStore.editingWorkblockAddress == elementAddress + '[' + elementIndex + ']',
			'border-2': element?.id in workflowProcessStore.history,
			'border-primary': workflowStore.editingWorkblockAddress == elementAddress + '[' + elementIndex + ']',
			'border-dashed': workflowStore.editingWorkblockAddress == elementAddress + '[' + elementIndex + ']',
			'active:shadow-none': sidebar || modal,
			'cursor-pointer': !sidebar,
			'cursor-grab': sidebar,
			'active:cursor-grabbing': sidebar,
			'opacity-20': disabled && !sidebar && !modal,
			'border-blue-500': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 1,
			'border-green-500': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 2,
			'border-orange-400': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 3,
			'border-red-500': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 4,
		}"
		:style="{
			width: `${workflowConfig.boxWidth / 4}px`,
			height: `${workflowConfig.boxHeight}px`,
		}"
	>
		<div class="flex w-full relative">
			<div class="flex w-full justify-center font-semibold text-lg">
				<FontAwesomeIcon
					:icon="faPersonToPortal"
					class="flex items-center justify-center w-3/5 h-full pointer-events-none text-purple-700"
					:class="{
						'text-purple-700': !element,
					}"
					:style="{
						color: element ? workflowStore.workflowExits[element.exit_point]?.color : false,
					}"
				/>
			</div>
			<div class="absolute flex w-1/4 h-full justify-end"></div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { workflowConfig } from '@/config/workflow';
	import { defineProps, withDefaults } from 'vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faPersonToPortal } from '@fortawesome/pro-light-svg-icons';
	import { WorkblockTemplate } from '@/types/graphql';
	import { WorkflowSchema } from '@/types/workflow';
	import { useWorkflowStore } from '@modules/workflow/store';
	import { useWorkflowProcessStore } from '@modules/workflow_process/store';

	const workflowStore = useWorkflowStore();
	const workflowProcessStore = useWorkflowProcessStore();

	interface Props {
		sidebar?: boolean;
		modal?: boolean;
		disabled?: boolean;
		template?: WorkblockTemplate;
		element?: WorkflowSchema;
		elementAddress?: string;
		elementIndex?: number;
	}

	const props = withDefaults(defineProps<Props>(), {
		sidebar: false,
		modal: false,
		disabled: false,
		template: undefined,
		element: undefined,
		elementAddress: undefined,
		elementIndex: undefined,
	});
</script>
