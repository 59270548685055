<template>
	<component
		:is="props.tag"
		:draggable="props.draggable"
		@drag="emitEvent(events.drag, $event)"
		@dragstart="emitEvent(events.dragstart, $event)"
		@dragenter="emitEvent(events.dragenter, $event)"
		@dragleave="emitEvent(events.dragleave, $event)"
		@dragend="emitEvent(events.dragend, $event)"
	>
		<slot :transfer-data="scopedData"></slot>
		<div v-if="props.hideImageHtml" :style="hideImageStyle">
			<slot name="image" :transfer-data="scopedData"></slot>
		</div>
		<slot v-else name="image" :transfer-data="scopedData"></slot>
	</component>
</template>

<script setup lang="ts">
	import { dropEffects, effectsAllowed, events } from '@/types/workflow';
	import { computed, defineProps, ref, useSlots, defineEmits, withDefaults } from 'vue';
	import { useWorkflowStore } from '@modules/workflow/store';
	import { WorkblockTemplate } from '@/types/graphql';

	interface Props {
		draggable?: boolean;
		transferData?: WorkblockTemplate;
		dropEffect?: dropEffects | null;
		effectAllowed?: effectsAllowed | null;
		image?: string;
		imageXOffset?: number;
		imageYOffset?: number;
		hideImageHtml?: boolean;
		tag?: string;
	}

	const props = withDefaults(defineProps<Props>(), {
		draggable: true,
		transferData: () => {
			return {} as WorkblockTemplate;
		},
		dropEffect: null,
		effectAllowed: null,
		image: '',
		imageXOffset: 0,
		imageYOffset: 0,
		hideImageHtml: true,
		tag: 'div',
	});

	const workflowStore = useWorkflowStore();
	const slots = useSlots();
	const emit = defineEmits(Object.values(events));

	const dragging = ref(false);

	const scopedData = computed(() => {
		return dragging.value && props.transferData;
	});
	const hideImageStyle = computed(() => {
		return { position: 'fixed', top: '-1000px' };
	});

	const emitEvent = (name: events, nativeEvent: DragEvent) => {
		const transfer = nativeEvent.dataTransfer;
		// Set drop effect on dragenter and dragover
		if ([events.dragenter, events.dragover].includes(name)) {
			if (props.dropEffect && transfer !== null) {
				transfer.dropEffect = props.dropEffect;
			}
		}
		// A number of things need to happen on drag start
		if (name === events.dragstart) {
			// Set the allowed effects
			if (props.effectAllowed && transfer !== null) {
				transfer.effectAllowed = props.effectAllowed;
			}
			// Set the drag image
			if (props.image || slots.image) {
				let image;
				if (props.image) {
					image = new Image();
					image.src = props.image;
				} else if (slots.image) {
					// eslint-disable-next-line vue/require-slots-as-functions
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					image = slots.image[0].elm;
				}
				if (transfer !== null && transfer.setDragImage) {
					transfer.setDragImage(image, props.imageXOffset, props.imageYOffset);
				}
			}
			// Set the transfer data
			if (props.transferData) {
				workflowStore.draggingElement = props.transferData;
			} else {
				workflowStore.draggingElement = false;
			}
			// Indicate that we're dragging.
			dragging.value = true;
		}
		// At last, emit the event.
		emit(name, props.transferData, nativeEvent);
		// Clean up stored data on drag end after emitting.
		if (name === events.dragend) {
			workflowStore.draggingElement = {} as WorkblockTemplate;
			dragging.value = false;
		}
	};
</script>
