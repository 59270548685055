<template>
	<div class="flex-1 h-full w-full select-none">
		<div
			class="h-full w-full flex"
			:class="{
				border: functionMode,
			}"
		>
			<div
				class="w-0 flex-grow-0 flex-shrink-0 relative transition-all duration-300 transform z-40 bg-gray-50 max-h-full overflow-y-scroll"
				:class="{
					'md:w-64': allowEdit,
					'border-r': allowEdit,
					'md:w-0': !allowEdit,
					'ml-0': showElements && allowEdit,
					'-ml-64': !showElements && allowEdit,
				}"
			>
				<div v-if="allowEdit" class="hidden md:block">
					<div>
						<div class="absolute top-0 left-0 right-0 pt-4 px-3 pb-2 dark:bg-dark-800">
							<div class="relative">
								<input type="text" class="pl-8 pr-3 py-1.25 rounded-lg placeholder-gray-400 placeholder-opacity-100 w-full border p-1" placeholder="Search elements" />
								<FontAwesomeIcon :icon="faMagnifyingGlass" class="inline-block text-inherit h-1em overflow-visible -align-0.125em absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-dark-400" />
							</div>
						</div>
						<div class="overflow-y-auto absolute left-0 right-0 pt-1 px-3 pb-3 top-16">
							<Elements />
						</div>
					</div>
				</div>
			</div>
			<div class="flex-1 bg-gray-100 relative dark:bg-dark-900 min-w-120">
				<div class="absolute top-4 -left-4 transform translate-x-full flex flex-col z-50">
					<div class="flex flex-col shadow-sm bg-white border divide-y">
						<UIButton :icon="faMagnifyingGlassPlus" :label-right="true" label="Zoom in" @click="zoomControl(ZoomType.in)" />
						<UIButton :icon="faMagnifyingGlassMinus" :label-right="true" label="Zoom out" @click="zoomControl(ZoomType.out)" />
						<UIButton :icon="faArrowsToCircle" :label-right="true" label="Center workflow" @click="zoomControl(ZoomType.center)" />
						<UIButton :icon="faArrowUpRightAndArrowDownLeftFromCenter" :label-right="true" label="Zoom to show all content" @click="zoomControl(ZoomType.fit)" />
					</div>
					<div v-if="!functionMode" class="flex flex-col shadow-sm bg-white border mt-3">
						<UIButton :icon="faPenToSquare" :selected="workflowStore.displayMode === WorkflowDisplayMode.Builder" :label-right="true" label="Builder mode" @click="workflowStore.setDisplayMode(WorkflowDisplayMode.Builder)" />
						<UIButton :icon="faEye" :selected="workflowStore.displayMode === WorkflowDisplayMode.Display" :label-right="true" label="Display mode" @click="workflowStore.setDisplayMode(WorkflowDisplayMode.Display)" />
					</div>
					<div class="flex flex-col shadow-sm bg-white border mt-3">
						<UIButton :icon="faInfo" :selected="workflowStore.showDetail" :label-right="true" label="Show details" @click="workflowStore.toggleDetailsMode()" />
					</div>
				</div>
				<div v-if="allowEdit" class="md:block absolute bottom-4 -left-4 transform translate-x-full flex flex-col z-50">
					<div class="flex flex-col shadow-sm bg-white border" @click="toggleSidePanel">
						<UIButton v-if="showElements" :icon="faArrowLeftFromLine" :label-right="true" label="Close panel" />
						<UIButton v-else :icon="faArrowRightFromLine" :label-right="true" label="Open panel" />
					</div>
				</div>
				<div class="absolute inset-0 overflow-y-auto">
					<div ref="workflowContent" class="flex flex-row h-full divide-solid divide-x divide-gray-500 divide-opacity-25">
						<PanZoom
							selector="#zoomable"
							:options="{
								bounds: false,
								initialY: -600,
								initialZoom: 1.3,
								filterKey: (/* e, dx, dy, dz */) => {
									return true;
								},
								beforeMouseDown(e: MouseEvent) {
									return checkClassList(e);
								},
								onTouch(e: TouchEvent) {
									return !checkClassList(e);
								},
								zoomDoubleClickSpeed: 1,
							}"
							class="overflow-hidden flex-grow relative cursor-move"
							@init="onInit"
						>
							<div id="zoomable" ref="panZoomContent" class="h-full flex flex-col grow absolute z-10">
								<StepView :element="workflowStore.getDisplayWorkflow" />
							</div>
							<div class="h-full w-full -z-1"></div>
						</PanZoom>
					</div>
				</div>
			</div>
			<div v-if="allowEdit" class="flex-grow-0 flex-shrink-0 bg-white relative transition-all duration-500 transform z-40 mr-0">
				<div class="absolute top-4 -left-4 transform -translate-x-full flex flex-col">
					<div class="flex flex-col shadow-sm bg-white border divide-y">
						<UIButton :icon="faArrowRotateLeft" :disabled="!workflowStore.canUndo" :label="'Undo' + (!workflowStore.canUndo ? ' (No changes to undo)' : '')" @click="workflowStore.undo()" />
						<UIButton :icon="faArrowRotateRight" :disabled="!workflowStore.canRedo" :label="'Redo' + (!workflowStore.canUndo ? ' (No changes to redo)' : '')" @click="workflowStore.redo()" />
					</div>
					<div class="flex flex-col shadow-sm bg-white border mt-3">
						<UIButton
							:icon="faGear"
							:selected="workflowStore.workflowSettingsOpen"
							label="Workflow settings"
							@click="workflowStore.workflowSettingsOpen ? workflowStore.closeWorkflowSettingsEditor() : workflowStore.openWorkflowSettingsEditor()"
						/>
					</div>
				</div>
				<div class="absolute bottom-4 -left-4 transform -translate-x-full flex flex-col">
					<div class="flex flex-col shadow-sm bg-white border">
						<UIButton :icon="faTrashCan" label="Clear workflow" @click="workflowStore.clearActiveWorkflow()" />
					</div>
				</div>
			</div>
			<div v-if="workflowStore.loading && !workflowStore.loaded" class="z-50 fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity">
				<div class="flex justify-center h-full w-full mx-auto">
					<Loading></Loading>
				</div>
			</div>
		</div>
		<ElementsModal />
	</div>
</template>

<script setup lang="ts">
	import { useBaseStore } from '@store';
	import Elements from '@modules/workflow/partials/Elements.vue';
	import type { Ref } from 'vue';
	import { defineProps, inject, nextTick, onMounted, onUnmounted, ref, watch, withDefaults } from 'vue';
	import { workflowConfig } from '@/config/workflow';
	import { useWorkflowStore } from '../store';
	import PanZoom from '../components/PanZoom.vue';
	import { PanZoom as PanZoomInstance } from 'panzoom';
	import StepView from '../components/StepView.vue';
	import { WorkflowDisplayMode, ZoomType } from '@/types/workflow';
	import { calcStepLength, calcWidth, getLeftPos } from '@modules/workflow/utils/helpers';
	import ElementsModal from '@modules/workflow/components/ElementsModal.vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import {
		faArrowLeftFromLine,
		faArrowRightFromLine,
		faArrowRotateLeft,
		faArrowRotateRight,
		faEye,
		faGear,
		faMagnifyingGlass,
		faMagnifyingGlassMinus,
		faMagnifyingGlassPlus,
		faPenToSquare,
		faTrashCan,
	} from '@fortawesome/pro-light-svg-icons';
	import { faArrowsToCircle, faArrowUpRightAndArrowDownLeftFromCenter } from '@fortawesome/pro-regular-svg-icons';
	import { faInfo } from '@fortawesome/pro-solid-svg-icons';
	import Loading from '@components/Loading.vue';
	import UIButton from '@components/UIButton.vue';

	interface Props {
		workflowId: string;
		allowEdit: boolean;
		functionMode?: boolean;
	}

	const props = withDefaults(defineProps<Props>(), {
		allowEdit: true,
		functionMode: false,
	});

	const checkClassList = (e: MouseEvent | TouchEvent) => {
		return hasSomeParentTheClass(e?.target as HTMLElement, 'workflow-element') ?? false;
	};
	const hasSomeParentTheClass = (element: HTMLElement, classname: string): boolean | null => {
		if (element?.classList?.contains('workflow-element')) return true;
		return element.parentNode && hasSomeParentTheClass(element.parentNode as HTMLElement, classname);
	};

	const baseStore = useBaseStore();
	const workflowStore = useWorkflowStore();
	baseStore.secondaryPanelOpen = false;
	baseStore.secondaryPanelClosed = true;
	const showElements = ref(true);

	const toggleSidePanel = () => {
		showElements.value = !showElements.value;
		setTimeout(() => {
			zoomControl(ZoomType.center);
		}, 300);
	};

	let panzoomInstance: PanZoomInstance;

	const gridX = ref(0);
	const gridY = ref(0);
	const gridWidth = ref(100);
	const gridHeight = ref(100);

	const workflowContent = ref();

	const onInit = (panzoom: PanZoomInstance) => {
		panzoom.on('pan', (panzoomEventInstance: PanZoomInstance) => {
			const transform = panzoomEventInstance.getTransform();
			const height = workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight;
			const { x, y, scale } = transform;
			gridX.value = x;
			gridY.value = y + (scale * height) / 2;
		});
		panzoom.on('zoom', (panzoomEventInstance: PanZoomInstance) => {
			const transform = panzoomEventInstance.getTransform();
			const height = workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight;
			const { scale, x, y } = transform;
			gridWidth.value = (scale * workflowConfig.gapWidth) / 2;
			gridHeight.value = scale * height;
			gridX.value = x;
			gridY.value = y + (scale * height) / 2;
		});
		panzoomInstance = panzoom;
		nextTick().then(() => {
			setTimeout(() => {
				zoomControl(ZoomType.fit);
			}, 500);
		});
	};

	const zoomControl = (zoomType: ZoomType) => {
		if (panzoomInstance && !!workflowStore.getDisplayWorkflow) {
			panzoomInstance.pause();
			try {
				const { gapHeight, gapWidth, exGapHeight } = workflowConfig;
				const height = (calcStepLength(workflowStore.getDisplayWorkflow) + 2) * (workflowStore.showDetail ? exGapHeight : gapHeight);
				const width = calcWidth(workflowStore.getDisplayWorkflow) * gapWidth;
				const offset = getLeftPos(workflowStore.getDisplayWorkflow) * gapWidth;
				const zoomData = { type: zoomType, height, width, offset, boundingRect: panZoomContent?.value?.getBoundingClientRect() };
				const { type } = zoomData;
				const contentWidth = workflowContent.value.clientWidth;
				const contentHeight = workflowContent.value.clientHeight;
				if (type === ZoomType.in) {
					panzoomInstance.smoothZoom(contentWidth / 2, contentHeight / 2, 2);
				} else if (type === ZoomType.out) {
					panzoomInstance.smoothZoom(contentWidth / 2, contentHeight / 2, 0.5);
				} else if (type === ZoomType.fit) {
					const { width, height, offset } = zoomData;
					const widthScale = contentWidth / width;
					const heightScale = contentHeight / height;
					let scale: number;
					let posX: number;
					let posY = 0;
					if (widthScale > heightScale) {
						scale = heightScale;
						posX = contentWidth / 2 - ((width + offset * 2) * scale) / 2;
					} else {
						scale = widthScale;
						posY = contentHeight / 2 - (height * scale) / 2;
						posX = -offset * scale;
					}
					panzoomInstance.moveTo(posX, posY);
					panzoomInstance.smoothZoomAbs(posX, posY, scale);
				} else if (type === ZoomType.center) {
					const { width, height, offset } = zoomData;
					const posX = contentWidth / 2 - ((width + offset * 2) * panzoomInstance.getTransform().scale) / 2;
					const posY = contentHeight / 2 - (height * panzoomInstance.getTransform().scale) / 2;
					panzoomInstance.smoothMoveTo(posX, posY);
				}
			} catch (err) {
				console.error(err);
			}
			panzoomInstance.resume();
		}
	};
	const panZoomContent = ref<HTMLInputElement | null>(null);

	onMounted(async () => {
		gridWidth.value = workflowConfig.gapWidth / 2;
		gridHeight.value = workflowConfig.gapHeight;
		await workflowStore.getWorkflowById(props.workflowId);

		if (props.functionMode) {
			await workflowStore.setDisplayMode(WorkflowDisplayMode.Display);
		}
		await workflowStore.getWorkblockTemplates();
		await workflowStore.getWorkblockActions();
	});

	watch(
		() => workflowStore.showDetail,
		async () => {
			if (panzoomInstance) {
				zoomControl(ZoomType.center);
			}
		},
	);

	watch(
		() => workflowStore.loaded,
		async () => {
			if (panzoomInstance) {
				zoomControl(ZoomType.fit);
			}
		},
	);

	watch(
		() => baseStore.currentBreakpoint,
		async () => {
			setTimeout(() => {
				zoomControl(ZoomType.fit);
			}, 300);
		},
	);

	watch(
		() => {
			return baseStore.secondaryPanelClosed;
		},
		(newValue) => {
			if (newValue) {
				zoomControl(ZoomType.center);
			} else {
				setTimeout(() => {
					zoomControl(ZoomType.center);
				}, 300);
			}
		},
	);

	const contentWidth = inject('contentWidth') as Ref<string>;
	watch(contentWidth, async () => {
		setTimeout(() => {
			zoomControl(ZoomType.center);
		}, 300);
	});

	onUnmounted(async () => {
		await workflowStore.clearLoadedWorkflow();
	});
</script>
