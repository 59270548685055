<template>
	<div
		class="flex justify-center items-center flex-col"
		:style="{
			left: `${props.element.position.x * workflowConfig.gapWidth}px`,
			top: `${props.element.position.y * (workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight)}px`,
			position: 'absolute',
			width: `${workflowConfig.gapWidth}px`,
			height: `${workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight}px`,
			'z-index': 100,
		}"
	>
		<VConnector :length="0.5" :start-y="0.5" :start-x="0.5" :direction="false" />
		<Drop
			v-if="workflowStore.draggingElement"
			class="drop flex justify-center items-center z-40"
			:style="{
				width: `${workflowConfig.boxWidth}px`,
				height: `${workflowConfig.boxHeight}px`,
			}"
			:class="{ 'bg-primary-200': over, 'shadow-inner': over, 'shadow-primary': over }"
			@drop="handleDrop"
			@dragover="handleDragOver"
			@dragleave="handleDragLeave"
		>
			<div v-if="!over" class="workflow-element cursor-pointer" :class="{ 'pointer-events-none': over }">
				<FontAwesomeIcon :icon="faCirclePlus" style="pointer-events: none" class="shrink-0 h-5 w-5 text-primary rounded-xl bg-white shadow-md" aria-hidden="true" />
			</div>
		</Drop>
		<div
			v-else
			class="flex justify-center items-center z-40"
			:style="{
				width: `${workflowConfig.boxWidth}px`,
				height: `${workflowConfig.boxHeight}px`,
			}"
		>
			<div class="workflow-element cursor-pointer" @click="onClick">
				<FontAwesomeIcon :icon="faCirclePlus" style="pointer-events: none" class="shrink-0 h-5 w-5 text-primary rounded-xl bg-white shadow-md" aria-hidden="true" />
			</div>
		</div>
		<VConnector :length="0.5" :start-y="0.5" :start-x="0.5" :direction="true" />
	</div>
</template>

<script setup lang="ts">
	import Drop from './Drop.vue';
	import { workflowConfig } from '@/config/workflow';
	import VConnector from './VConnector.vue';
	import { defineProps, ref } from 'vue';
	import { useWorkflowStore } from '../store';
	import { WorkflowSchema } from '@/types/workflow';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';

	const workflowStore = useWorkflowStore();

	interface Props {
		element: WorkflowSchema;
		elementAddress: string;
		elementIndex: number;
	}

	const props = defineProps<Props>();
	const over = ref(false);

	const handleDrop = () => {
		if (workflowStore.draggingElement) {
			over.value = false;
			workflowStore.addElementToActiveWorkflowWithAddress(props.elementAddress, props.elementIndex);
		}
	};
	const handleDragLeave = () => {
		if (workflowStore.draggingElement) {
			over.value = false;
		}
	};
	const handleDragOver = () => {
		if (workflowStore.draggingElement) {
			over.value = true;
		}
	};

	const onClick = () => {
		workflowStore.workflowElementsModal.insertAddress = props.elementAddress;
		workflowStore.workflowElementsModal.insertIndex = props.elementIndex;
		workflowStore.workflowElementsModal.open = true;
	};
</script>