<template>
	<div
		class="workflow-element flex flex-col justify-center items-center relative z-20"
		:style="{
			width: `${workflowConfig.gapWidth}px`,
			height: `${workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight}px`,
			top: `${element.position.y * (workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight)}px`,
		}"
	>
		<div
			class="flex bg-white justify-center items-center rounded-full z-30 cursor-pointer shadow-md"
			:style="{
				width: `${workflowConfig.boxWidth}px`,
				height: `${workflowConfig.boxHeight}px`,
				transform: `${workflowStore.showDetail && workflowStore.displayMode == WorkflowDisplayMode.Builder ? 'translateY(-45px)' : 'none'}`,
			}"
			:class="{
				'border-2': element?.id in workflowProcessStore.history,
				'border-blue-500': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 1,
				'border-green-500': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 2,
				'border-orange-400': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 3,
				'border-red-500': element?.id in workflowProcessStore.history && workflowProcessStore.history[element.id]['status'] == 4,
			}"
		>
			<div class="flex w-full h-full relative items-center">
				<div class="flex w-full justify-center font-semibold text-lg">End</div>
				<div class="absolute flex w-1/4 h-full justify-end items-center ml-2">
					<FontAwesomeIcon :icon="faStop" class="flex items-center justify-center h-3/4 pointer-events-none text-red-500" />
				</div>
			</div>
		</div>
		<VConnector v-if="!workflowStore.showDetail" :length="0.5" :start-y="0.5" :start-x="0.5" :direction="false" />
		<VConnector v-else :length="0.5" :start-y="0" :start-x="0.5" :direction="workflowStore.displayMode == WorkflowDisplayMode.Display" />
	</div>
</template>

<script setup lang="ts">
	import { workflowConfig } from '@/config/workflow';
	import VConnector from './VConnector.vue';
	import { defineProps } from 'vue';
	import { useWorkflowStore } from '../store';
	import { WorkflowDisplayMode, WorkflowSchema } from '@/types/workflow';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faStop } from '@fortawesome/pro-solid-svg-icons';
	import { useWorkflowProcessStore } from '@modules/workflow_process/store';

	const workflowStore = useWorkflowStore();
	const workflowProcessStore = useWorkflowProcessStore();

	interface Props {
		element: WorkflowSchema;
		elementAddress: string;
		elementIndex: number;
	}

	defineProps<Props>();
</script>
