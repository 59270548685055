<template>
	<TransitionRoot as="template" :show="workflowStore.workflowElementsModal.open">
		<Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="workflowStore.workflowElementsModal.open = false">
			<div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
						<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
							<button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500" @click="workflowStore.workflowElementsModal.open = false">
								<span class="sr-only">Close</span>
								<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
							</button>
						</div>
						<div class="sm:flex sm:items-start">
							<div class="flex flex-row flex-grow items-center">
								<div class="flex flex-row flex-grow justify-center">
									<div class="text-center sm:ml-4 sm:text-left justify-center">
										<DialogTitle as="h1" class="text-3xl font-medium text-gray-900"> Add new element </DialogTitle>
									</div>
								</div>
								<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"></div>
							</div>
						</div>
						<div class="my-6">
							<Elements :mode="WorkflowElementMode.Button" />
						</div>
						<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
							<button
								type="button"
								class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
								@click="workflowStore.workflowElementsModal.open = false"
							>
								Close
							</button>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup lang="ts">
	import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import { useWorkflowStore } from '@modules/workflow/store';
	import Elements from '@modules/workflow/partials/Elements.vue';
	import { WorkflowElementMode } from '@/types/workflow';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faXmark } from '@fortawesome/pro-light-svg-icons';

	const workflowStore = useWorkflowStore();
</script>
