<template v-if="source">
	<svg class="connector-svg flow-line z-10" style>
		<polyline :points="getPoints" fill="none" stroke-width="1.5px" stroke="black" />
	</svg>
</template>

<script setup lang="ts">
	import { workflowConfig } from '@/config/workflow';
	import { computed, defineProps, withDefaults } from 'vue';
	import { ElementPosition } from '@/types/workflow';
	import { useWorkflowStore } from '../store';

	const workflowStore = useWorkflowStore();
	interface Props {
		source: ElementPosition;
		target: ElementPosition;
	}
	const props = withDefaults(defineProps<Props>(), {
		source: () => {
			return { x: 0, y: 0 } as ElementPosition;
		},
		target: () => {
			return { x: 0, y: 0 } as ElementPosition;
		},
	});

	const getPoints = computed(() => {
		if (props.source && props.target) {
			const { gapHeight, gapWidth, exGapHeight } = workflowConfig;
			const correctGapHeight = workflowStore.showDetail ? exGapHeight : gapHeight;
			const sourceX = props.source.x * gapWidth;
			const sourceY = props.source.y * correctGapHeight;
			const targetX = props.target.x * gapWidth;
			const targetY = props.target.y * correctGapHeight;
			if (!Number.isNaN(targetX) && !Number.isNaN(sourceX) && !Number.isNaN(targetY) && !Number.isNaN(sourceY)) {
				if (sourceX == targetX) {
					return `${sourceX} ${sourceY - 0.75},  ${targetX} ${targetY + 0.75}`;
				} else {
					return `${sourceX - 0.75} ${sourceY},  ${targetX + 0.75} ${targetY}`;
				}
			} else {
				return '0 0, 0 0';
			}
		} else {
			return '0 0, 0 0';
		}
	});
</script>

<style scoped>
	.connector-svg {
		overflow: visible;
		position: absolute;
		top: 0;
		left: 0;
	}

	.connector-svg > polyline {
		overflow: visible;
	}
</style>
