<template>
	<div ref="vuePanZoom" class="vue-pan-zoom">
		<slot></slot>
	</div>
</template>
<script setup lang="ts">
	import panZoom, { PanZoom, PanZoomOptions } from 'panzoom';
	import { computed, defineProps, defineEmits, watch, ref, onUnmounted } from 'vue';

	const props = defineProps<{
		options: PanZoomOptions;
		selector: string;
	}>();

	const emits = defineEmits<{
		(e: 'init', instance: PanZoom): void;
		(e: 'panstart', instance: PanZoom): void;
		(e: 'panend', instance: PanZoom): void;
		(e: 'pan', instance: PanZoom): void;
		(e: 'zoom', instance: PanZoom): void;
		(e: 'transform', instance: PanZoom): void;
		(e: 'zoomend', instance: PanZoom): void;
	}>();

	const defaultOptions: PanZoomOptions = {
		autocenter: true,
		bounds: true,
		transformOrigin: {
			x: 0.5,
			y: 0.5,
		},
	};

	let panZoomInstance: undefined | PanZoom = undefined;

	const vuePanZoom = ref<HTMLElement | SVGElement>();

	const scene = computed<HTMLElement | SVGElement | null>((): HTMLElement | SVGElement | null => {
		const wrapperElement: HTMLElement | SVGElement | undefined = vuePanZoom.value;
		if (wrapperElement) {
			if (props.selector) {
				return wrapperElement.querySelector(props.selector);
			} else {
				return wrapperElement.querySelector('svg, object, embed');
			}
		}
		return null;
	});

	const createPanZoomInstance = (instanceScene: HTMLElement | SVGElement) => {
		const instanceOptions: PanZoomOptions = Object.assign({}, defaultOptions, props.options);
		panZoomInstance = panZoom(instanceScene, instanceOptions);
		attachEvents(panZoomInstance);
	};

	watch(
		(): HTMLElement | SVGElement | undefined => {
			if (scene.value !== null) {
				return scene.value;
			}
		},
		(newSceneValue: HTMLElement | SVGElement | undefined) => {
			if (newSceneValue) {
				if (panZoomInstance) {
					// if a panzoom instance has already been created
					panZoomInstance.dispose();
				}
				createPanZoomInstance(newSceneValue);
			}
		}
	);

	const attachEvents = (panZoomInstance: PanZoom) => {
		emits('init', panZoomInstance);

		panZoomInstance.on('panstart', (instance: PanZoom) => {
			emits('panstart', instance);
		});

		panZoomInstance.on('panend', (instance: PanZoom) => {
			emits('panend', instance);
		});

		panZoomInstance.on('pan', (instance: PanZoom) => {
			emits('pan', instance);
		});

		panZoomInstance.on('zoom', (instance: PanZoom) => {
			emits('zoom', instance);
		});

		panZoomInstance.on('transform', (instance: PanZoom) => {
			emits('transform', instance);
		});

		panZoomInstance.on('zoomend', (instance: PanZoom) => {
			emits('zoomend', instance);
		});
	};

	onUnmounted(() => {
		if (panZoomInstance) {
			// if a panzoom instance has been created dispose of it to avoid any memory issues.
			panZoomInstance.dispose();
		}
	});
</script>

<style>
	div.vue-pan-zoom {
		height: 100%;
		width: 100%;
	}
</style>
