<template>
	<div>
		<div v-for="workflowElementCategoryId in Object.values(WorkflowElementCategory)" :key="workflowElementCategoryId" class="overflow-hidden">
			<button type="button" class="flex items-center justify-between w-full py-2 font-medium text-left border-b border-gray-200" @click="toggleWorkflowElementCategory(workflowElementCategoryId)">
				<span>{{ WorkflowElementCategoryNames[workflowElementCategoryId] }}</span>
				<FontAwesomeIcon
					:icon="faChevronDown"
					class="w-6 h-6 shrink-0 transition-all duration-150 transform-gpu"
					:class="{
						'rotate-180': openCategories.includes(workflowElementCategoryId),
					}"
				/>
			</button>
			<!-- Sliding sidebar -->
			<TransitionRoot
				:show="openCategories.includes(workflowElementCategoryId)"
				enter="transition-opacity duration-75"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="transition-opacity duration-150"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div v-if="mode == WorkflowElementMode.Drag" class="flex flex-col justify-center items-center space-y-0.5 my-2 bg-transparent">
					<Drag
						v-for="element in workflowStore.workblockTemplates.filter(function (el) {
							return el.category_id == workflowElementCategoryId;
						})"
						:key="element.id"
						class="drag w-fit translate-y-0 translate-x-0 bg-transparent p-1"
						:class="{
							['drag-element-' + element.id]: true,
						}"
						:transfer-data="element"
					>
						<StepContent v-if="element.type_id == WorkflowObjectType.STEP" :template="element" :sidebar="true"></StepContent>
						<BranchContent v-else-if="element.type_id == WorkflowObjectType.BRANCH" :sidebar="true" :template="element" />
						<EntryContent v-else-if="element.type_id == WorkflowObjectType.ENTRY" :sidebar="true" :template="element" />
						<ExitContent v-else-if="element.type_id == WorkflowObjectType.EXIT" :sidebar="true" :template="element" />
						<HaltContent v-else-if="element.type_id == WorkflowObjectType.HALT" :sidebar="true" :template="element" />
					</Drag>
				</div>
				<div v-else class="flex flex-col justify-center items-center space-y-0.5 my-2 bg-transparent">
					<button
						v-for="element in workflowStore.workblockTemplates.filter(function (el) {
							return el.category_id == workflowElementCategoryId;
						})"
						:key="element.id"
						class="drag w-fit translate-y-0 translate-x-0 bg-transparent p-1"
						@click="onButtonClicked(element)"
					>
						<StepContent v-if="element.type_id == WorkflowObjectType.STEP" :template="element" :sidebar="false" :modal="true"></StepContent>
						<BranchContent v-else-if="element.type_id == WorkflowObjectType.BRANCH" :sidebar="false" :modal="true" :template="element"></BranchContent>
						<EntryContent v-else-if="element.type_id == WorkflowObjectType.ENTRY" :sidebar="false" :modal="true" :template="element" />
						<ExitContent v-else-if="element.type_id == WorkflowObjectType.EXIT" :sidebar="false" :modal="true" :template="element" />
						<HaltContent v-else-if="element.type_id == WorkflowObjectType.HALT" :sidebar="true" :template="element" />
					</button>
				</div>
			</TransitionRoot>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { defineProps, ref, withDefaults } from 'vue';
	import Drag from '../components/Drag.vue';
	import BranchContent from '../components/BranchContent.vue';
	import StepContent from '../components/StepContent.vue';
	import { TransitionRoot } from '@headlessui/vue';
	import { WorkflowElementCategory, WorkflowElementCategoryNames, WorkflowElementMode, WorkflowObjectType } from '@/types/workflow';
	import { useWorkflowStore } from '@modules/workflow/store';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
	import { WorkblockTemplate } from '@/types/graphql';
	import EntryContent from '@modules/workflow/components/EntryContent.vue';
	import ExitContent from '@modules/workflow/components/ExitContent.vue';
	import HaltContent from '@modules/workflow/components/HaltContent.vue';

	interface Props {
		mode?: WorkflowElementMode;
	}

	withDefaults(defineProps<Props>(), {
		mode: WorkflowElementMode.Drag,
	});

	const openCategories = ref<number[]>([]);
	const workflowStore = useWorkflowStore();

	const toggleWorkflowElementCategory = (workflowCategory: number) => {
		if (openCategories.value.includes(workflowCategory)) {
			openCategories.value = openCategories.value.filter((expandedCategory) => {
				return expandedCategory !== workflowCategory;
			});
			return;
		}
		openCategories.value.push(workflowCategory);
	};
	const onButtonClicked = (element: WorkblockTemplate) => {
		workflowStore.draggingElement = element;
		workflowStore.addElementToActiveWorkflowWithAddress(workflowStore.workflowElementsModal.insertAddress, workflowStore.workflowElementsModal.insertIndex);
		workflowStore.workflowElementsModal.open = false;
	};
</script>
