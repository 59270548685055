<template>
	<svg class="connector-svg z-10">
		<line
			:x1="workflowConfig.gapWidth * startX"
			:y1="(workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight) * startY"
			:x2="workflowConfig.gapWidth * startX"
			:y2="
				direction
					? (workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight) * startY + (workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight) * length
					: (workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight) * startY - (workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight) * length
			"
			stroke="black"
			stroke-width="1.5px"
		/>
	</svg>
</template>

<script setup lang="ts">
	import { workflowConfig } from '@/config/workflow';
	import { defineProps, withDefaults } from 'vue';
	import { useWorkflowStore } from '../store';

	const workflowStore = useWorkflowStore();

	interface Props {
		direction: boolean;
		length: number;
		startX: number;
		startY: number;
	}
	withDefaults(defineProps<Props>(), {
		length: 0,
		startX: 0,
		startY: 0,
	});
</script>

<style scoped>
	.connector-svg {
		overflow: visible;
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.connector-svg > line {
		overflow: visible;
	}
</style>
